import React, { useEffect } from "react";

import axios from "axios";

import { loadSDK } from "../libs";
import { useViewerSDK } from "../containers/viewer.context";
import { Loader } from "./loader";

import { ITargetNode, IOnNodeSelect } from "../types/viewer3dSdk";
import "./viewer.scss";

import imgPoweredBy from "../assets/images/other/powered-by.png";

export const Viewer = (props: any) => {
  const { setViewerSDK, isNodeLoaded, setNodeLoaded } = useViewerSDK();
  const urlParams = window.location.search;
  const params = new URLSearchParams(urlParams);
  const paramType = params.get("type");
  let paramAnimations = params.get("animations") || true;
  let platformType: string;

  switch (true) {
    case props.width <= 700:
      platformType = "mobile";
      if (!params.get("animations")) {
        paramAnimations = false;
      }
      break;

    case props.width >= 700 && props.width <= 1023:
      platformType = "tablet";
      break;

    default:
      platformType = "desktop";
      break;
  }

  useEffect(
    () => {
      const awaitLoadSdk = async () => {
        try {
          const viewerConfig = await axios.get(
            "./assets/viewer/viewer-config.json"
          );
          const { sdk, viewer, customDataSources } = viewerConfig.data;
          const customDataSourcesArr = Object.keys(customDataSources);
          const viewerSDK: any = await loadSDK(sdk);

          if (
            customDataSourcesArr.length > 0 &&
            paramType &&
            customDataSourcesArr.includes(paramType)
          ) {
            viewer[platformType].dataSource.url = customDataSources[paramType];
          } else if (paramType && !customDataSourcesArr.includes(paramType)) {
            throw new Error(
              "type parameter does not exists in viewerConf customDataSources"
            );
          }

          Object.entries(viewer[platformType]).forEach(
            ([key, value]: [string, any]) => {
              if (key === "renderer") {
                value["width"] =
                  value["width"] !== null ? value["width"] : props.width - 30;

                value["height"] =
                  value["height"] !== null ? value["height"] : props.width - 30;
              }

              viewerSDK._3DViewer.loadConfiguration(key, value);
            }
          );

          viewerSDK._3DViewer.addEventListener(
            "onNodeReady",
            (targetNode: ITargetNode) => {
              setNodeLoaded(true);
            }
          );

          // TODO: only select the first clicked
          viewerSDK._3DViewer.addEventListener(
            "onNodeSelect",
            ([targetNode, origin, selectedNodeList]: IOnNodeSelect) => {
              viewerSDK._3DViewer
                .getCameraAPI()
                .setState(targetNode.meta?.cameraState, {
                  duration: !paramAnimations ? 0 : 3000,
                  onFinish: () => {
                    props.toogleMarkerModal();
                    props.setModalMarkerTitle(targetNode.meta?.title);
                    props.setModalMarkerContext(targetNode.meta?.context);
                  },
                });
            }
          );

          if (viewerSDK) {
            setViewerSDK(viewerSDK);
          }
        } catch (error) {}
      };

      if (!isNodeLoaded) {
        awaitLoadSdk();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // props,
      // isNodeLoaded,
      // paramAnimations,
      // platformType,
      // setViewerSDK,
      // sdk,
      // setNodeLoaded,
      // viewer,
    ]
  );

  return (
    <>
      {!isNodeLoaded && <Loader />}
      <div id="app" style={{ display: isNodeLoaded ? "block" : "none" }}></div>
      {!isNodeLoaded && (
        <div className="powered-by">
          <img src={imgPoweredBy} alt="Powered by Alive Vision" />
        </div>
      )}
    </>
  );
};
