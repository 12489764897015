import React from "react";
import "@st-graphics/react-scrolly/dist/bundle.css";
import StScrolly from "@st-graphics/react-scrolly";
import { useWindowSize } from "@react-hook/window-size";
import { slidesConf } from "../config";
import { panoElement, BgrImage } from ".";
import {
  marzipanoScenes,
  switchMarzipanoScene,
  marzipanoViewer,
} from "../libs";
import { animate } from "../core";
import { BgrColor } from "./bgr-color";

export const Scrolly = (props: any) => {
  const [width, height] = useWindowSize();
  const slidesArr = slidesConf({ height, width });
  let currentScene: string;

  // TODO: this should go to a lib file. as this WILL GROW a lot . . .
  const renderBackground = ({
    slideIndex,
    progress,
  }: StScrolly.ExposedScope) => {
    const p = progress.at(slideIndex)();
    const slide: any = slidesArr[slideIndex]; // TODO: remove any

    if (slide && slide.background) {
      if (slide.background.type === "color") {
        return <BgrColor style={slide.background.style} />;
      }

      if (slide.background.type === "image") {
        if (panoElement) {
          panoElement.style.display = "none";
        }

        const styles = {
          backgroundImage: `url(../../assets/images/backgrounds/${slide.background.fileName})`,
          height,
        };

        return <BgrImage style={styles} />;
      }

      if (slide.background.type === "pano" && marzipanoScenes) {
        if (panoElement) {
          panoElement.style.display = "block";
        }

        if (currentScene === slide.background.fileName) {
          animate(marzipanoViewer, p, slide.animations);
        } else {
          const scene = marzipanoScenes.find(
            (scene: any) => scene.data.id === slide.background.fileName
          );

          if (scene) {
            currentScene = slide.background.fileName;
            switchMarzipanoScene(scene);
          }
        }
      }
    }
  };

  let platformType = "";

  switch (true) {
    case width <= 700:
      platformType = "mobile";
      break;

    case width >= 700 && width <= 1023:
      platformType = "tablet";
      break;

    default:
      platformType = "desktop";
      break;
  }

  const $slides = slidesArr.map((slide: any, i: number) => (
    <div
      className="slide"
      style={{
        height: slide.multiplier[platformType]
          ? height * slide.multiplier[platformType] * slide.steps.length
          : height * slide.steps.length,
        overflow: "hidden",
        // border: "2px solid green",
      }}
      key={i}
    >
      {slide.steps.map((step: any) => step)}
    </div>
  ));

  return <StScrolly renderBackground={renderBackground}>{$slides}</StScrolly>;
};
