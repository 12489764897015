import React from "react";
import "./slide-seven.scss";

export const SlideSevenStepOne = (props: any) => {
  return (
    <div className="step slide--seven" key="slide-2">
      <h1 className="title">Richard Butler</h1>
      <h2 className="subtitle">DP Review</h2>
      <p>
        “The Fujifilm GFX 100 is the 100 Megapixel medium format camera that the
        company has been promising since Photokina 2018.”
      </p>
    </div>
  );
};
