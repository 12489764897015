import React from "react";
import {
  SlideOneStepOne,
  SlideSixStepOne,
  SlideSevenStepOne,
} from "../components";
import { SlideTwoStepOne } from "../components";
import { SlideThreeStepOne } from "../components";
import { SlideFourStepOne } from "../components";
import { SlideFiveStepOne } from "../components";

export const slidesConf = ({ ...args }) => [
  {
    background: {
      type: "color",
      style: {
        backgroundColor: "#000000",
      },
    },
    multiplier: {
      mobile: 1,
      tablet: 1,
      desktop: 1,
    },
    steps: [
      <SlideOneStepOne
        key="slide-1-step-1"
        width={args.width}
        height={args.height}
      />,
    ],
  },
  {
    background: {
      type: "color",
      style: {
        backgroundColor: "#000000",
      },
    },
    multiplier: {
      mobile: 1.1,
      tablet: 1,
      desktop: 1.45,
    },
    steps: [
      <SlideTwoStepOne
        key="slide-2-step-1"
        width={args.width}
        height={args.height}
      />,
    ],
  },
  {
    multiplier: {
      mobile: 1,
      tablet: 1,
      desktop: 2.9,
    },
    steps: [
      <SlideThreeStepOne
        key="slide-3-step-1"
        width={args.width}
        height={args.height}
      />,
    ],
  },
  {
    multiplier: {
      mobile: 1.3,
      tablet: 1,
      desktop: 1.8,
    },
    steps: [
      <SlideFourStepOne
        key="slide-4-step-1"
        width={args.width}
        height={args.height}
      />,
    ],
  },
  {
    multiplier: {
      mobile: 1.55,
      tablet: 1,
      desktop: 2.5,
    },
    steps: [
      <SlideFiveStepOne
        key="slide-5-step-1"
        width={args.width}
        height={args.height}
      />,
    ],
  },
  {
    multiplier: {
      mobile: 0.9,
      tablet: 1,
      desktop: 0.75,
    },
    steps: [
      <SlideSixStepOne
        key="slide-6-step-1"
        width={args.width}
        height={args.height}
      />,
    ],
  },
  {
    multiplier: {
      mobile: 1,
      tablet: 1,
      desktop: 1,
    },
    steps: [
      <SlideSevenStepOne
        key="slide-7-step-1"
        width={args.width}
        height={args.height}
      />,
    ],
  },
];
