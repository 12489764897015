import { marzipanoConf } from "../config";
const Marzipano = require("marzipano");

export let marzipanoScenes: any[];
export let marzipanoViewer: any;

export const marzipanoInit = (element: HTMLElement) => {
  const viewerOpts = {
    controls: {
      mouseViewMode: marzipanoConf.settings.mouseViewMode,
    },
  };

  marzipanoViewer = new Marzipano.Viewer(element, viewerOpts);
  marzipanoScenes = marzipanoConf.scenes.map((data: any) => {
    const view = new Marzipano.RectilinearView(
      data.initialViewParameters,
      Marzipano.RectilinearView.limit.traditional(
        data.faceSize,
        (100 * Math.PI) / 180,
        (120 * Math.PI) / 180
      )
    );

    const scene = marzipanoViewer.createScene({
      source: Marzipano.ImageUrlSource.fromString(
        `./assets/threesixty/tiles/${data.id}/{z}/{f}/{y}/{x}.jpg`,
        {
          cubeMapPreviewUrl: `./assets/threesixty/tiles/${data.id}/preview.jpg`,
        }
      ),
      geometry: new Marzipano.CubeGeometry(data.levels),
      view: view,
      pinFirstLevel: true,
    });

    return {
      data: data,
      scene: scene,
      view: view,
    };
  });
};

export const animateMarzipano = (
  viewer: any,
  progress: number,
  animation: any
) => {
  const view = viewer.scene().view();
  const interpolatedValues = {
    yaw:
      animation.values.yaw[0] +
      (animation.values.yaw[1] - animation.values.yaw[0]) *
        ((progress - animation.timeline[0]) /
          (animation.timeline[1] - animation.timeline[0])),
    pitch:
      animation.values.pitch[0] +
      (animation.values.pitch[1] - animation.values.pitch[0]) *
        ((progress - animation.timeline[0]) /
          (animation.timeline[1] - animation.timeline[0])),
    fov:
      animation.values.fov[0] +
      (animation.values.fov[1] - animation.values.fov[0]) *
        ((progress - animation.timeline[0]) /
          (animation.timeline[1] - animation.timeline[0])),
  };

  view.setParameters(interpolatedValues);
};

export const switchMarzipanoScene = (scene: any) => {
  scene.view.setParameters(scene.data.initialViewParameters);
  scene.scene.switchTo();
};
