import React from "react";
import { createPortal } from "react-dom";

export const Modal = ({
  toggle,
  title,
  children,
  customClass,
  toggleCallback,
}: any) =>
  createPortal(
    <React.Fragment>
      <div
        className="modal-wrapper"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div
          className="modal-overlay"
          onClick={() => {
            toggle();
            toggleCallback();
          }}
        />

        <div className={"modal " + customClass}>
          <div className="modal-header">
            <div className="title-header">
              <h2>{title ? title : ""}</h2>
            </div>
            <button
              type="button"
              className="modal-close-button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                toggle();
                toggleCallback();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {children}
        </div>
      </div>
    </React.Fragment>,
    document.body
  );
