import React from "react";
import "./slide-two.scss";
import gfx100_main from "../../assets/images/slides/gfx100_main.jpg";

export const SlideTwoStepOne = (props: any) => {
  return (
    <div className="step slide--two" key="slide-2">
      <h1 className="title">Preserve for the Future</h1>
      <img src={gfx100_main} alt="Fujifilm GFX100" />
      <p>
        The GFX, FUJIFILM’s mirrorless digital camera system, has achieved the
        highest level of image quality in the world of professional photography.
        The system continues its evolution…
      </p>
      <br />
      <br />
      <p>
        The GFX100 uses a 55mm diagonal length*1 large format CMOS sensor with
        over 100 million pixels which makes this the highest class resolution
        mirrorless digital camera ever produced. The GFX100, is the coming
        together of FUJIFILM’s unique blend of imaging and optical technologies
        and heralds the future of photography for the years to come.
      </p>
    </div>
  );
};
