import React from "react";

export const BgrImage = (props: any) => (
  <div
    style={{
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      width: "100%",
      ...props.style,
    }}
  ></div>
);
