import React, { useState } from "react";

const ViewerSDKContext = React.createContext<any>([]);

const ViewerSDKProvider: React.FC = (props) => {
  const [viewerSDK, setViewerSDK] = useState<any>(null);
  const [isNodeLoaded, setNodeLoaded] = useState<boolean>(false);

  return (
    <ViewerSDKContext.Provider
      value={{
        setViewerSDK,
        viewerSDK,
        isNodeLoaded,
        setNodeLoaded,
      }}
      {...props}
    />
  );
};

const useViewerSDK = () => {
  const context = React.useContext(ViewerSDKContext);

  if (context === undefined) {
    throw new Error("useViewerSDK must be used within a ContainerDrops");
  }

  return context;
};

export { ViewerSDKProvider, useViewerSDK };
