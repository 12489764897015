import React from "react";

export const BgrColor = (props: any) => (
  <div
    style={{
      height: "100%",
      width: "100%",
      ...props.style,
    }}
  ></div>
);
