import { animateMarzipano } from "../libs/marzipano";

export const animate = (viewer: any, progress: number, animations: any) => {
  animations.forEach((animation: any) => {
    switch (animation.type) {
      case "marzipano":
        animateMarzipano(viewer, progress, animation);
        break;

      default:
        break;
    }
  });
};
