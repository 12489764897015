import React, { useEffect } from "react";
import { useWindowSize } from "@react-hook/window-size";
import { marzipanoInit } from "../libs";

export let panoElement: HTMLElement | null;

export const Marzipano = (props: any) => {
  const [height, width] = useWindowSize();

  useEffect(() => {
    panoElement = document.querySelector("#pano");

    if (panoElement) {
      marzipanoInit(panoElement);
    }
  }, []);

  return (
    <div
      id={props.id}
      style={{ height, width, position: "fixed", top: 0, left: 0 }}
    ></div>
  );
};
