import React from "react";
import "./slide-four.scss";
import gfx100_01 from "../../assets/images/slides/gfx100_01.jpg";
import ff_gfx100_2_opt from "../../assets/images/slides/ff_gfx100_2-opt.jpg";

export const SlideFourStepOne = (props: any) => {
  return (
    <div className="step slide--four" key="slide-2">
      <h1 className="title">Body</h1>
      <div className="content">
        <img src={gfx100_01} alt="GFX100" />
        <p>
          Despite featuring a sensor which is 1.7 times larger than a 35mm full
          frame sensor, the GFX100’s body is equivalent to that of a flagship
          35mm full frame DSLR camera in terms of dimensions (156.2mm (W) x
          163.6mm (H) x 102.9mm (D), measuring 48.9mm at the thinnest part) and
          weight (approx. 1400g including two batteries, memory card and EVF).
        </p>
      </div>
      <img src={ff_gfx100_2_opt} alt="GFX100" />
    </div>
  );
};
