import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faAngleDoubleDown,
  faCompass,
} from "@fortawesome/free-solid-svg-icons";

export const fontAwesomeInit = () => {
  library.add(faBars, faAngleDoubleDown, faCompass);
};
