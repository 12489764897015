import { ISdkConfig } from "../types/viewer3dSdk";

export const loadSDK = (sdk: ISdkConfig) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = `./libs/${sdk.fileName}`;
    script.onload = () => resolve(initViewer3d());
    script.onerror = () => console.info("Error on loading SDK... ");

    document.body.appendChild(script);
  });
};

const initViewer3d = () => {
  const viewerSDK = new (window as any).DecideSDK();
  viewerSDK.showViewer(viewerSDK.listAvailableViewers()[0]);

  window.addEventListener("move3dCamera", (e) => {
    const position = {
      x: (e as CustomEvent).detail.x,
      y: (e as CustomEvent).detail.y,
      z: (e as CustomEvent).detail.z,
    };
    viewerSDK._3DViewer.getCameraAPI().setPosition(position, false);
  });

  window.dispatchEvent(new CustomEvent("readyViewer3d"));

  return viewerSDK;
};

export const move3dCamera = (details: any, progress: number) => {
  if (details.type === "moveCamera3d") {
    const from = details.opts.from;
    const to = details.opts.to;

    const x = from.x + (to.x - from.x) * progress;
    const y = from.y + (to.y - from.y) * progress;
    const z = from.z + (to.z - from.z) * progress;

    window.dispatchEvent(
      new CustomEvent("move3dCamera", { detail: { x: x, y: y, z: z } })
    );
  }
};
