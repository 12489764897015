import React, { useEffect } from "react";
import { gsap } from "gsap";
import "./loader.scss";

export const Loader = (props: any) => {
  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1 });

    tl.to(".tri", { duration: 1.4, autoAlpha: 0.7, stagger: { amount: 0.9 } });
    tl.to(
      ".tri",
      { duration: 1, autoAlpha: 0, stagger: { amount: 1.4 } },
      "-=1"
    );

    tl.timeScale(2);
  }, []);

  return (
    <svg
      id="loader"
      xmlns="http://www.w3.org/2000/svg"
      width="172"
      height="149.48"
    >
      <path
        d="M960 470h-43l21.5-37.24 21.5-37.24 21.5 37.24L1003 470z"
        transform="translate(-874 -395.52)"
        fill="#ffffff"
        className="tri shape 1"
      />
      <path
        d="M1003 545h-43l21.5-37.24 21.5-37.24 21.5 37.24L1046 545z"
        transform="translate(-874 -395.52)"
        fill="#ffffff"
        className="tri shape 3"
      />
      <path
        d="M917 544.74h-43l21.5-37.24 21.5-37.24 21.5 37.24 21.5 37.24z"
        transform="translate(-874 -395.52)"
        fill="#ffffff"
        className="tri shape 2"
      />
    </svg>
  );
};
