import React, { useEffect } from "react";
import "./App.scss";

// import { Marzipano, Scrolly, Header } from "./components";
import { Scrolly, Header } from "./components";
import { fontAwesomeInit } from "./libs/font-awesome";
import { ViewerSDKProvider } from "./containers/viewer.context";

const App = () => {
  fontAwesomeInit();

  useEffect(() => {
    console.log("DONE loading...");
  }, []);

  return (
    <ViewerSDKProvider>
      <div className="App">
        <Header />
        {/* <Marzipano id="pano" /> */}
        <Scrolly />
      </div>
    </ViewerSDKProvider>
  );
};

export default App;
