import React from "react";
import "./slide-six.scss";
import gfx100_03 from "../../assets/images/slides/gfx100_03.jpg";

export const SlideSixStepOne = (props: any) => {
  return (
    <div className="step slide--six" key="slide-2">
      <h1 className="title">Stabilization</h1>
      <div className="content">
        <img src={gfx100_03} alt="GFX100" />
        <p>
          Generally speaking, the higher the pixel count an image sensor has,
          the higher the likelihood of camera shake. To avoid this threat to
          image quality, FUJIFILM has designed an IBIS system specifically for
          the new 102MP large format sensor which gives up to 5.5 stops *2 of
          image stabilization.
        </p>
      </div>
    </div>
  );
};
