import React, { useState } from "react";
import "./slide-one.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bounce, fadeIn } from "react-animations";
import { StyleSheet, css } from "aphrodite";

import { Viewer } from "..";
import { useViewerSDK } from "../../containers/viewer.context";
import { ToggleModal } from "../../core";
import { ITargetNode } from "../../types/viewer3dSdk";

import imgIntroLogo from "../../assets/images/slides/intro-title.png";

const bounceKeyFrames = {
  "0%": {
    transform: "translateX(0)",
  },

  "50%": {
    transform: "translateY(10px)",
  },

  "100%": {
    transform: "translateX(0)",
  },
};

const compassKeyFrames = {
  "0%": {
    transform: "scale(0)",
  },

  "50%": {
    transform: "scale(1.2)",
  },

  "100%": {
    transform: "scale(1)",
  },
};

const fadeInKeyFrames = {
  "0%": {
    opacity: 0.4,
  },
  "100%": {
    opacity: 1,
  },
};

const infoTitleFadeInKeyFrames = {
  "0%": {
    opacity: 0.2,
  },
  "100%": {
    opacity: 0.4,
  },
};

const styles = StyleSheet.create({
  loading: {
    opacity: 0.4,
  },
  loadingInfoTitle: {
    opacity: 0.2,
  },
  bounce: {
    animationName: [bounce, bounceKeyFrames],
    animationDuration: "10s, 2500ms",
    animationIterationCount: "infinite",
  },
  fadeIn: {
    animationName: [fadeIn, fadeInKeyFrames],
    animationDuration: "5s",
  },
  compass: {
    animationName: [bounce, compassKeyFrames],
    animationDuration: "2s",
  },
  infoTitleFadeIn: {
    animationName: [fadeIn, infoTitleFadeInKeyFrames],
    animationDuration: "5s",
  },
});

export const SlideOneStepOne = (props: any) => {
  const [modalMarkerTitle, setModalMarkerTitle] = useState<string | undefined>(
    ""
  );
  const [modalMarkerContext, setModalMarkerContext] = useState("");
  const { viewerSDK, isNodeLoaded } = useViewerSDK();
  const {
    toggle: toogleMenuModal,
    RenderModal: RenderMenuModal,
  } = ToggleModal();
  const {
    toggle: toogleMarkerModal,
    RenderModal: RenderMarkerModal,
  } = ToggleModal();
  const {
    toggle: toogleHelpModal,
    RenderModal: RenderHelpModal,
  } = ToggleModal();
  const urlParams = window.location.search;
  const params = new URLSearchParams(urlParams);
  let paramAnimations = params.get("animations") || true;
  let platformType = "";

  const onMenuSelect = (id: string) => {
    toogleMenuModal();
    const marker: ITargetNode = viewerSDK._3DViewer
      .getDataSourceAPI()
      .getNodeById(id);

    viewerSDK._3DViewer.getNodeAPI().selectNode(marker);
    viewerSDK._3DViewer.getCameraAPI().setState(marker.meta?.cameraState, {
      duration: !paramAnimations ? 0 : 3000,
      onFinish: () => {
        toogleMarkerModal();
        setModalMarkerTitle(marker.meta?.title);
        setModalMarkerContext(marker.meta?.context);
      },
    });
  };

  const toggleCallback = () => {
    viewerSDK._3DViewer.getNodeAPI().deselectAllNodes();
  };

  const onMenuHelpSelect = () => {
    toogleMenuModal();
    toogleHelpModal();
  };

  switch (true) {
    case props.width <= 700:
      platformType = "mobile";
      if (!params.get("animations")) {
        paramAnimations = false;
      }
      break;

    case props.width >= 700 && props.width <= 1023:
      platformType = "tablet";
      break;

    default:
      platformType = "desktop";
      break;
  }

  const menu = (
    <ul style={{ listStyleType: "none", padding: 0, margin: 0, width: "100%" }}>
      <li
        style={{
          borderBottom: "1px solid #000000",
          paddingBottom: 10,
          marginBottom: 10,
          color: "#000000",
        }}
      >
        EXPLORE ANIMATION
      </li>
      <li
        style={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <button onClick={() => onMenuSelect("marker_01")}>
          GFX100 Overview
        </button>
      </li>
      <li
        style={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <button onClick={() => onMenuSelect("marker_02")}>Body</button>
      </li>
      <li
        style={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <button onClick={() => onMenuSelect("marker_03")}>
          Sensor / Processor
        </button>
      </li>
      <li
        style={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <button onClick={() => onMenuSelect("marker_04")}>Stabilization</button>
      </li>
      <li
        style={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <button onClick={() => onMenuSelect("marker_05")}>Grip</button>
      </li>
      <li
        style={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <button onClick={() => onMenuSelect("marker_06")}>Main Monitor</button>
      </li>
      <li
        style={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <button onClick={() => onMenuSelect("marker_07")}>
          Rear Sub Monitor
        </button>
      </li>
      <li
        style={{
          borderTop: "1px solid #000000",
          paddingTop: 10,
          marginTop: 10,
        }}
      >
        <button onClick={() => onMenuHelpSelect()}>HELP</button>
      </li>
    </ul>
  );

  return (
    <div
      className="step slide--one"
      key="step-1"
      style={{
        paddingTop: 50,
        height: props.height - 50,
      }}
    >
      <RenderHelpModal title="Touch Control">
        <p>Here will be the help system</p>
      </RenderHelpModal>
      <RenderMarkerModal
        toggleCallback={toggleCallback}
        title={modalMarkerTitle}
      >
        <p
          style={{
            fontFamily: "Noto Sans",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "18px",
            lineHeight: "22px",
          }}
        >
          {modalMarkerContext}
        </p>
      </RenderMarkerModal>

      {platformType !== "desktop" ? (
        <RenderMenuModal customClass="menu">{menu}</RenderMenuModal>
      ) : (
        <div
          style={{ position: "absolute" }}
          className="menu--container-desktop"
        >
          {menu}
        </div>
      )}
      {platformType !== "desktop" && (
        <div className="menu--container" onClick={toogleMenuModal}>
          <FontAwesomeIcon
            // style={{ display: isNodeLoaded ? "block" : "none" }}
            // className={css(isNodeLoaded && styles.compass)}
            color="#FFFFFF"
            icon="compass"
          />
        </div>
      )}
      <div
        className={
          "title " +
          css(!isNodeLoaded && styles.loading, isNodeLoaded && styles.fadeIn)
        }
      >
        <img src={imgIntroLogo} alt="Fujifilm intro title" />
      </div>
      <div className="viewer">
        <Viewer
          width={props.width}
          height={props.height}
          toogleMarkerModal={toogleMarkerModal}
          setModalMarkerTitle={setModalMarkerTitle}
          setModalMarkerContext={setModalMarkerContext}
        />
      </div>
      <div className="info-content">
        <div className="info">
          <div
            className={
              "title " +
              css(
                !isNodeLoaded && styles.loadingInfoTitle,
                isNodeLoaded && styles.infoTitleFadeIn
              )
            }
          >
            Sensor
          </div>
          <div
            className={
              "content " +
              css(
                !isNodeLoaded && styles.loading,
                isNodeLoaded && styles.fadeIn
              )
            }
          >
            43.8mm x 32.9mm
          </div>
        </div>
        <div className="info">
          <div
            className={
              "title " +
              css(
                !isNodeLoaded && styles.loadingInfoTitle,
                isNodeLoaded && styles.infoTitleFadeIn
              )
            }
          >
            Effective Pixels
          </div>
          <div
            className={
              "content " +
              css(
                !isNodeLoaded && styles.loading,
                isNodeLoaded && styles.fadeIn
              )
            }
          >
            102million pixels
          </div>
        </div>
        <div className="info">
          <div
            className={
              "title " +
              css(
                !isNodeLoaded && styles.loadingInfoTitle,
                isNodeLoaded && styles.infoTitleFadeIn
              )
            }
          >
            Mount
          </div>
          <div
            className={
              "content " +
              css(
                !isNodeLoaded && styles.loading,
                isNodeLoaded && styles.fadeIn
              )
            }
          >
            FUJIFILM G Mount
          </div>
        </div>
      </div>
      <div
        className={
          "scroll-down " +
          css(!isNodeLoaded && styles.loading, isNodeLoaded && styles.fadeIn)
        }
      >
        <FontAwesomeIcon
          className={css(isNodeLoaded && styles.bounce)}
          color="#FFFFFF"
          icon="angle-double-down"
        />
      </div>
    </div>
  );
};
