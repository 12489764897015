import React from "react";
import "./slide-three.scss";
import ff_gfx100_3_opt from "../../assets/images/slides/ff_gfx100_3-opt.jpg";

export const SlideThreeStepOne = (props: any) => {
  return (
    <div className="step slide--three" key="slide-2">
      <img src={ff_gfx100_3_opt} alt="GFX100" />
    </div>
  );
};
