import React from "react";
import "./header.scss";
import logo from "../assets/images/other/logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Header = () => {
  return (
    <div id="header">
      <div id="logo">
        <img src={logo} alt="Fujifilm" />
      </div>
      <div id="menu">
        <FontAwesomeIcon icon="bars" />
      </div>
    </div>
  );
};
