import React from "react";
import "./slide-five.scss";
import gfx100_02 from "../../assets/images/slides/gfx100_02.jpg";
import ff_gfx100_1_opt from "../../assets/images/slides/ff_gfx100_1-opt.jpg";

export const SlideFiveStepOne = (props: any) => {
  return (
    <div className="step slide--five" key="slide-2">
      <h1 className="title">Sensor / Processor</h1>
      <div className="content">
        <img src={gfx100_02} alt="GFX100" />
        <p>
          The GFX, FUJIFILM’s mirrorless digital camera system, has achieved the
          highest level of image quality in the world of professional
          photography. The system continues its evolution…
        </p>
      </div>
      <img src={ff_gfx100_1_opt} alt="GFX100" />
    </div>
  );
};
