import React, { useState } from "react";
import { Modal } from "../components";

export const ToggleModal = () => {
  const [isActive, setActive] = useState(false);

  const toggle = () => {
    setActive(!isActive);
  };

  const RenderModal = ({
    title,
    children,
    customClass,
    toggleCallback,
  }: {
    title?: string;
    children: React.ReactChild;
    customClass?: string;
    toggleCallback?: () => void;
  }) => {
    return isActive ? (
      <Modal
        customClass={customClass ? customClass : ""}
        title={title}
        toggle={toggle}
        toggleCallback={toggleCallback}
      >
        {children}
      </Modal>
    ) : null;
  };

  return {
    toggle,
    RenderModal,
    isActive,
  };
};
